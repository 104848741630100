import supplierAppServices from '../../../services/supplier/application'

import Infos from '../../share/application-setting/Infos'
import Domains from '../../share/application-setting//Domains'
import Settings from '../../share/application-setting//Settings'
import Acknowledgment from '../../share/application-setting//Acknowledgment'
import Completion from '../../share/application-setting//Completion'

export default {
    components: {
        Infos,
        Domains,
        Settings,
        Acknowledgment,
        Completion
    },
    data() {
        return {
            item: {},
            key: 0,
            mountedKey: 0,
            services: supplierAppServices
        }
    },
    mounted() {
        this.getProfile()
    },
    methods: {
        getProfile() {
            this.services.getProfile().then(resp => {
                if (!resp.error) {
                    this.key += 1
                    this.$nextTick(() => {
                        this.item = resp.data.d
                        this.mountedKey += 1
                    })
                }
            })
        },
        onCompleted() {
            this._showToast('Update Completed!')
            this.getProfile()
        }
    }
}